<template>
  <secondary-page-layout>
    <div class="form-group">
      <div class="primary-block">
        <ul class="input-list">
          <li class="input-list-item py-3">
            <input type="text" :placeholder="$t('cardNumber')" class="input">
            <div>
              <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.3926 5.65723C17.8584 5.65723 18.1045 5.39355 18.1045 4.93652V2.7832C18.1045 0.972656 17.1816 0.0673828 15.3447 0.0673828H13.1914C12.7344 0.0673828 12.4795 0.313477 12.4795 0.770508C12.4795 1.22754 12.7344 1.48242 13.1914 1.48242H15.3184C16.1885 1.48242 16.6895 1.94824 16.6895 2.8623V4.93652C16.6895 5.39355 16.9443 5.65723 17.3926 5.65723ZM1.59863 5.65723C2.06445 5.65723 2.31055 5.39355 2.31055 4.93652V2.8623C2.31055 1.94824 2.79395 1.48242 3.67285 1.48242H5.7998C6.26562 1.48242 6.52051 1.22754 6.52051 0.770508C6.52051 0.313477 6.26562 0.0673828 5.7998 0.0673828H3.65527C1.81836 0.0673828 0.895508 0.972656 0.895508 2.7832V4.93652C0.895508 5.39355 1.15039 5.65723 1.59863 5.65723ZM3.65527 17.2676H5.7998C6.26562 17.2676 6.52051 17.0127 6.52051 16.5645C6.52051 16.1074 6.26562 15.8525 5.7998 15.8525H3.67285C2.79395 15.8525 2.31055 15.3867 2.31055 14.4727V12.3984C2.31055 11.9326 2.05566 11.6777 1.59863 11.6777C1.1416 11.6777 0.895508 11.9326 0.895508 12.3984V14.543C0.895508 16.3623 1.81836 17.2676 3.65527 17.2676ZM13.1914 17.2676H15.3447C17.1816 17.2676 18.1045 16.3535 18.1045 14.543V12.3984C18.1045 11.9326 17.8496 11.6777 17.3926 11.6777C16.9355 11.6777 16.6895 11.9326 16.6895 12.3984V14.4727C16.6895 15.3867 16.1885 15.8525 15.3184 15.8525H13.1914C12.7344 15.8525 12.4795 16.1074 12.4795 16.5645C12.4795 17.0127 12.7344 17.2676 13.1914 17.2676Z" fill="#2AA65C"/>
              </svg>
            </div>
          </li>
          <li class="input-list-item py-3">
            <input type="text" class="input" :placeholder="$t('cardDate')">
            <input type="password" class="input" placeholder="CVC/CVV" maxlength="3">
            <div class="question-icon">?</div>
          </li>
        </ul>
      </div>
    </div>
    <a href="" class="primary-btn bottom-page-btn">{{$t('payButton')}}</a>
  </secondary-page-layout>
</template>

<script>
export default {
  name: "AddNewCreditCard"
}
</script>

<style lang="scss" scoped>
@import "src/sass/variables";
.question-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 24px;
  width: 100%;
  height: 24px;
  color: #fff;
  background: $primary-color;
  border-radius: 50%;
}
</style>